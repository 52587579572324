<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Customers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Customers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  class="mr-2"
                  @click="$refs.customerDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Customer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Customers</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="customers"
        class="mt-4"
        no-data-text="No Customers found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-craigtoun-customers-individual',
                  params: { customerId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.customerDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <customer-dialog ref="customerDialog" />
    <v-dialog v-model="deleteCustomer.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Customer</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteCustomer.customer.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteCustomer.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomerDialog from "./components/CustomerDialog.vue";
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    CustomerDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Customers",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteCustomer: {
        dialog: false,
        customer: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(customer) {
      this.deleteCustomer.customer = customer;
      this.deleteCustomer.dialog = true;
    },

    resetDelete() {
      this.deleteCustomer.dialog = false;
      this.deleteCustomer.customer = {};
      this.deleteCustomer.loading = false;
    },

    saveDelete() {
      this.deleteCustomer.loading = true;

      this.$store
        .dispatch("craigtoun/customersStore/deleteCustomer", {
          appId: this.$route.params.id,
          customerId: this.deleteCustomer.customer.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteCustomer.loading = false;
        });
    },
  },

  computed: {
    customers() {
      let customers = this.$store.getters["craigtoun/customersStore/all"];

      if (this.searchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return customers;
    },
  },
};
</script>
